import React, { useMemo } from 'react';
import useQueryProjectCostPerPointGraph from 'hooks/graphql/useQueryProjectCostPerPointGraph/useQueryProjectCostPerPointGraph';
import { Chart } from 'react-charts';
import { projectShape } from 'utils/shapes/project';
import styled from 'styled-components/macro';

const ChartWrapper = styled.div`
  width: 100%;
  height: 12.5rem;
`;

function getWeek(date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return `${date.getFullYear()}__${Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)}`;
}

CostPerPointGraph.propTypes = {
  project: projectShape.isRequired,
};

export function CostPerPointGraph({ project }) {
  const { data, loading, error } = useQueryProjectCostPerPointGraph({
    projectId: project.id,
  });

  const graphData = useMemo(() => {
    if (!data) {
      return [{ label: project.name, data: [] }];
    }

    return [
      {
        label: project.name,
        data: data.projectCostPerPointGraphData.data.reduce((acc, datum) => {
          const date = new Date(datum.date);
          const week = getWeek(date);
          if (!acc.some((d) => d.week === week)) {
            acc.push({
              week,
              date,
              dollars: datum.costPerPoint.dollars,
              hours: datum.costPerPoint.hours,
            });
          }
          return acc;
        }, []),
      },
    ];
  }, [data, project.name]);

  const primaryAxis = useMemo(
    () => ({
      getValue: (datum) => datum.date,
    }),
    []
  );

  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (datum) => datum.hours,
        // padBandRange: true,
        min: 0,
        max: Math.max(10, graphData[0].data.reduce((acc, datum) => Math.max(acc, datum.hours), 0) + 2),
      },
    ],
    [graphData]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  console.log('graphData: ', graphData);

  return (
    <ChartWrapper>
      <Chart
        options={{
          data: graphData,
          primaryAxis,
          secondaryAxes,
        }}
      />
    </ChartWrapper>
  );
}

export default CostPerPointGraph;
