import React from 'react';
import { Reveal } from 'react-foundation';
import useQueryInvoiceNotes from 'hooks/graphql/useQueryInvoiceNotes/useQueryInvoiceNotes';

export default function InvoiceNotesModal({ project, id }) {
  const { data, loading, error } = useQueryInvoiceNotes({ projectId: project.id });

  return (
    <Reveal id={id || 'invoiceNotesModal'} isLarge>
      {loading && <p>Loading invoice notes...</p>}
      This is the content to reveal
      {!!data?.invoiceNotes &&
        data?.invoiceNotes.map((invoiceNote) => <div key={invoiceNote.id}>{invoiceNote.notes}</div>)}
      <button className='close-button' data-close aria-label='Close modal' type='button'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </Reveal>
  );
}
