import { useQuery } from '@apollo/client';
import PROJECT_COST_PER_POINT_GRAPH_QUERY from './projectCostPerPointGraphQuery';

export default function useQueryProjectCostPerPointGraph(input) {
  console.log('input: ', input);
  return useQuery(PROJECT_COST_PER_POINT_GRAPH_QUERY, {
    variables: {
      input,
    },
  });
}
