import { gql } from '@apollo/client';

const PROJECT_COST_PER_POINT_GRAPH_QUERY = gql`
  query ProjectCostPerPointGraphQuery($input: ProjectCostPerPointGraphInput!) {
    projectCostPerPointGraphData(input: $input) {
      projectId
      data {
        date
        costPerPoint {
          dollars
          hours
        }
      }
    }
  }
`;

export default PROJECT_COST_PER_POINT_GRAPH_QUERY;
