import React, { useState } from 'react';
import { Colors } from 'react-foundation';
import Button from 'components/Button';
import InvoiceNotesModal from 'components/InvoiceNotesModal/InvoiceNotesModal';

export default function InvoiceNotesModalTrigger({ project }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button color={Colors.SECONDARY} onClick={() => setIsOpen(true)} data-open='invoiceNotesModal'>
        Edit Invoice Notes
      </Button>
      <InvoiceNotesModal id='invoiceNotesModal' project={project} />
    </>
  );
}
